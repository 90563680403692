import React, { useState } from 'react';
import './Post.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentAlt, faHeart as faHeartOutline } from '@fortawesome/free-regular-svg-icons';
import { faHeart as faHeartSolid, faExchangeAlt, faChartSimple, faTrash } from '@fortawesome/free-solid-svg-icons';
import Comment from './Comment';

const Post = ({ postList, omCommentRemove }) => {
    const [likedPosts, setLikedPosts] = useState({});
    const [commentMap, setCommentMap] = useState({});
    const [newCommentMap, setNewCommentMap] = useState({});
    const [isCommentsVisible, setIsCommentsVisible] = useState({});

    const handleLike = (index) => {
        setLikedPosts((prevLiked) => ({
            ...prevLiked,
            [index]: !prevLiked[index]
        }));
    };

    const handleAddCommentToggle = (index) => {
        setIsCommentsVisible((prevVisibility) => ({
            ...prevVisibility,
            [index]: !prevVisibility[index]
        }));
    };

    const handleAddNewComment = (e, index) => {
        e.preventDefault();
        setCommentMap((prevComments) => ({
            ...prevComments,
            [index]: [
                ...(prevComments[index] || []),
                newCommentMap[index] || ''
            ]
        }));
        setNewCommentMap((prevNewComment) => ({
            ...prevNewComment,
            [index]: ''
        }));
        setIsCommentsVisible((prevVisibility) => ({
            ...prevVisibility,
            [index]: false
        }));
    };

    const handleRemoveComment = (postIndex, commentIndex) => {
        setCommentMap((prevComments) => ({
            ...prevComments,
            [postIndex]: prevComments[postIndex].filter((_, i) => i !== commentIndex)
        }));
    };

    const handleCommentChange = (e, index) => {
        const newLocal = e.target.value;
        const value = newLocal;
        setNewCommentMap((prevNewComment) => ({
            ...prevNewComment,
            [index]: value
        }));
    };

    return (
        postList.map((post, index) => {
            if (post.isActive) {
                return (
                    <div className='post-container' key={index}>
                        <div className="post">
                            <div className="header-content">
                                <span className="username">{post.user}</span>
                                <div className='muted'>
                                    <span className="date"> 22m.</span>
                                </div>
                            </div>

                            <div className="post-content">
                                <p>{post.content}</p>
                                {post.imageUrl && <img src={post.imageUrl} alt="Post" className="post-image" />}
                            </div>

                            <hr className="divider" />

                            <div className="post-actions">
                                <div onClick={() => handleLike(index)}>
                                    {likedPosts[index] ? (
                                        <FontAwesomeIcon icon={faHeartSolid} className="heart-icon-filled" />
                                    ) : (
                                        <FontAwesomeIcon icon={faHeartOutline} />
                                    )}
                                </div>
                                <span className="icon-padding"></span>
                                <FontAwesomeIcon icon={faExchangeAlt} />
                                <span className="icon-padding"></span>

                                <div onClick={() => handleAddCommentToggle(index)}>
                                    <FontAwesomeIcon icon={faCommentAlt} />
                                </div>
                                <span className="icon-padding"></span>
                                <FontAwesomeIcon icon={faChartSimple} />
                            </div>

                            <hr className="divider" />

                            {isCommentsVisible[index] && (
                                <div>
                                    <form onSubmit={(e) => handleAddNewComment(e, index)}>
                                        <input
                                            type="text"
                                            value={newCommentMap[index] || ''}
                                            onChange={(e) => handleCommentChange(e, index)}
                                            placeholder="Añadir un comentario..."
                                        />
                                        <button type="submit">+</button>
                                    </form>
                                </div>
                            )}

                            {commentMap[index] && commentMap[index].map((comment, commentIndex) => (
                                <Comment
                                    key={commentIndex}
                                    content={comment}
                                    onRemove={() => handleRemoveComment(index, commentIndex)}
                                />
                            ))}
                        </div>
                    </div>
                );
            }
            return null;
        })
    );
};

export default Post;