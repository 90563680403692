import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import './Comment.css'; 

const Comment = ({ content, onRemove }) => {
    return (
        <div className="comment-container">
            <div className="comment-content">{content}</div>
            <FontAwesomeIcon icon={faTrash} onClick={onRemove} className="comment-remove"/>
        </div>
    );
};

export default Comment;
